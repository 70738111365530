import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputMask } from '../../../components/Inputs/InputMask';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import Progress from '../../../components/progress-bar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { loadServerImage } from '../../../utils/loadServerImage';
import messageRequestError from '../../../utils/messageRequestError';
import {
  IUserProfile,
  emptyUserProfile,
} from '../../profile/dtos/IUserProfile';
import TopMenu from '../top-menu';

const UserEdit: React.FC = () => {
  const params: { id: string } = useParams();

  const router = useHistory();
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<any>(null);
  const toast = useToastContext();

  const today = new Date();

  const [load, setLoad] = useState(false);
  const [userProfile, setUserProfile] =
    useState<IUserProfile>(emptyUserProfile);

  const handleUploadAvatar = async (d: any) => {};
  const handleRemoveAvatar = async (d: any) => {};

  const handleSetAvatarUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const profileLoad = window.URL.createObjectURL(e.target.files[0]);
      setUserProfile({ ...userProfile, avatar_url: profileLoad });
    }
  };
  const handleSubmit = async (d: any) => {
    formRef.current?.setErrors({});
    d.birthday = userProfile.birthday;

    try {
      const validation = Yup.object({
        name: Yup.string().required('Por favor, informe um nome!'),
        email: Yup.string()
          .email('Por favor, forneça emal válido!')
          .required('Por favor, informe um email!'),
        cpf_cnpj: Yup.string().required('Por favor, informe um Cpf/Cnpj!'),
        phone: Yup.string().required('Por favor, informe um telefone!'),
        birthday: Yup.date().required(
          'Por favor, informe sua data de aniversário!',
        ),
      });
      await validation.validate(d, { abortEarly: false });

      await api
        .put(`/users/profile`, {
          user: {
            id: userProfile.id,
            key_user: userProfile.key_user,
            name: userProfile.name,
            email: userProfile.email,
            avatar_url: userProfile.avatar_url,
            cpf_cnpj: userProfile.cpf_cnpj,
            phone: userProfile.phone,
            birthday: userProfile.birthday,
            status: userProfile.status,
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Perfil editado com sucesso!');
            router.push('/users/list');
          }
        })
        .finally(() => setLoad(false));
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    }
  };

  const loadUser = async (id: string) => {
    setLoad(true);
    await api
      .get(`/users/${id}`)
      .then(({ data }) => {
        if (data) {
          setUserProfile({
            ...data,
            birthday: data.birthday === null ? today : data.birthday,
          });
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    if (validate(params.id)) {
      loadUser(params.id);
    }
  }, []);

  return (
    <>
      <TopMenu />
      <Progress isLoad={load} className="w-6" />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="flex justify-content-start"
      >
        <div className="card w-6 mr-2">
          <div className="p-fluid grid formgrid">
            <div className="field col-12 mb-0 pb-0">
              <div className="relative max-w-max m-auto">
                <input
                  type="file"
                  className="hidden"
                  ref={avatarRef}
                  onChange={e => handleSetAvatarUrl(e)}
                />
                {userProfile.avatar_url !== '' ? (
                  <Avatar
                    image={loadServerImage(userProfile.avatar_url)}
                    className="mr-2"
                    size="large"
                    shape="circle"
                    style={{ width: 84, height: 84 }}
                  />
                ) : (
                  <Avatar
                    icon="pi pi-user"
                    className="mr-2"
                    size="xlarge"
                    shape="circle"
                    style={{ width: 84, height: 84 }}
                  />
                )}
                <Button
                  icon="pi pi-plus"
                  className="p-button-rounded absolute"
                  style={{ left: '-8px', bottom: '-8px' }}
                  onClick={e => handleUploadAvatar(e)}
                />
                <Button
                  icon="pi pi-minus"
                  className="p-button-rounded p-button-danger absolute"
                  aria-label="Cancel"
                  onClick={e => handleRemoveAvatar(e)}
                  style={{ bottom: '-8px', left: '52px' }}
                />
              </div>
              <div className="col text-center mt-5">
                <span>{userProfile.name || '[Sem nome]'}</span>
              </div>
            </div>

            <div className="field col-12">
              <label htmlFor="name">Nome</label>
              <InputText
                name="name"
                value={userProfile.name}
                onChange={e =>
                  setUserProfile({
                    ...userProfile,
                    name: e.currentTarget.value,
                  })
                }
                placeholder="Ex.: João da Silva"
              />
            </div>

            <div className="field col-12">
              <label htmlFor="email">E-Mail</label>
              <InputText
                name="email"
                placeholder="Ex.: seuMelhor@email.com"
                value={userProfile.email}
                disabled
                readOnly
                className="surface-200"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="cpf_cnpj">CPF</label>
              <InputMask
                mask="999.999.999-99"
                name="cpf_cnpj"
                value={userProfile.cpf_cnpj}
                placeholder="000.000.000-00"
                onChange={e =>
                  setUserProfile({ ...userProfile, cpf_cnpj: e.value })
                }
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="phone">Telefone/Whatsapp</label>
              <InputMask
                mask="(99) 99999-9999"
                name="phone"
                value={userProfile.phone}
                placeholder="(00) 00000-0000"
                onChange={e =>
                  setUserProfile({ ...userProfile, phone: e.value })
                }
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Aniversário</label>
              <Calendar
                name="birthday"
                dateFormat="dd/mm/yy"
                showIcon
                value={userProfile.birthday as Date}
                onChange={e =>
                  setUserProfile({
                    ...userProfile,
                    birthday: e.value as Date,
                  })
                }
                placeholder={format(
                  new Date(userProfile.birthday),
                  'dd/MM/yyyy',
                )}
              />
            </div>

            <div className="field col-3 flex align-items-center justify-content-center">
              <InputSwitch
                name={'status'}
                checked={userProfile.status === 'A' ? true : false}
                onChange={e => {
                  setUserProfile({
                    ...userProfile,
                    status: userProfile.status === 'A' ? 'C' : 'A',
                  });
                }}
                className="mr-3"
              />
              <label htmlFor="dropdown" className="m-0">
                Ativo
              </label>
            </div>

            <div className="field col-3 flex align-items-center justify-content-center">
              <InputSwitch
                name={'key_user'}
                checked={userProfile.key_user}
                onChange={e => {
                  setUserProfile({
                    ...userProfile,
                    key_user: e.value,
                  });
                }}
                className="mr-3"
              />
              <label htmlFor="dropdown" className="m-0">
                Admin
              </label>
            </div>

            <Divider />

            <div className="field col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
              />
            </div>

            <div className="field col-6">
              <Button
                label="Editar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={load}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default UserEdit;
