import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Dialog as ModalPayments } from '../../../components/Modal';
import useToastContext from '../../../hooks/toast';
import { FormHandles } from '@unform/core';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import {
  emptyBillPayable,
  emptyBillPayableParcel,
  IBillPayableParcel,
  ICreateBillPayable,
} from '../dtos';
import { IBillsToPayList } from '../dtos/IBillsToPay';
import getValidationErrors from '../../../utils/getErrorsValidation';
import PerfectDivision from '../../../utils/perfectDivision';
import { v4 } from 'uuid';
import { addMonths, subMonths } from 'date-fns';
import api from '../../../services/api';
import { Form } from '@unform/web';
import { InputText } from '../../../components/Inputs/InputText';
import { Button } from 'primereact/button';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { DataTable } from '../../../components/DataTable';
import {
  Column,
  ColumnEditorOptions,
  ColumnEventParams,
} from 'primereact/column';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import { InputNumber, InputNumberChangeParams } from 'primereact/inputnumber';
import formatDecimal from '../../../utils/numbers/FormatDecimal';
import { Calendar } from 'primereact/calendar';
import { decimalEditor } from '../../../components/DataTable/decimal-editor';
import { dateEditor } from '../../../components/DataTable/date-editor';
import { Divider } from '../../../components/Divider';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  data: any;
}

export const ModalCreateBillPayable = ({
  isOpen,
  onRequestClose,
  data,
}: IProps): React.ReactElement => {
  const today = new Date();

  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);

  const [costCenters, setCostCenters] = useState<IOptionsDTO[]>([]);
  const [paymentConditions, setPaymentConditions] = useState<IOptionsDTO[]>([]);

  const [bill, setBill] = useState<ICreateBillPayable>(emptyBillPayable);
  const [parcel, setParcel] = useState<IBillPayableParcel>(
    emptyBillPayableParcel,
  );

  const loadCostCenter = async () => {
    setIsLoad(true);
    await api
      .get('/cost-center')
      .then(({ data }) => {
        if (data) {
          setCostCenters(
            data.map((item: any) => {
              return {
                label: item.title,
                value: item.id,
              };
            }),
          );
        }
      })
      .finally(() => setIsLoad(false));
  };

  const loadPaymentCondition = async () => {
    setIsLoad(true);
    await api
      .get('/payments-condition')
      .then(({ data }) => {
        if (data) {
          setPaymentConditions(
            data.map((item: any) => {
              return {
                value: item.id,
                label: item.title,
              };
            }),
          );
        }
      })
      .finally(() => setIsLoad(false));
  };

  const receiveData = async (d: Partial<IBillsToPayList>) => {
    if (d) {
      setBill({
        ...bill,
        doc_serie: d.ser || '',
        doc_number: d.number || '',
        provider_id: d.provider_id || '',
        provider_name: d.provider_name,
        value: Number(d.value),
        date_to_pay: addMonths(today, 1),
        parcels: [],
        cost_center_id:
          costCenters.find(c => c.label === 'Despesas')?.value || '',
        payment_condition_id:
          paymentConditions.find(p => p.label === 'Dinheiro')?.value || '',
      });
    }
  };

  const generateParcels = async () => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      const schema = Yup.object({
        value: Yup.number().required('Informe o valor total do documento'),
        parcel: Yup.number().required('Informe a quantidade de parcelas'),
        date_to_pay: Yup.date().required('Informe o vencimento inicial'),
        cost_center_id: Yup.string()
          .uuid()
          .required('Informe o centro de custo'),
      });

      await schema.validate(bill, { abortEarly: false });

      const parcelValues = PerfectDivision(
        Number(bill.value),
        Number(bill.parcel) || 1,
      );

      let items: IBillPayableParcel[] = [];

      for (let i = 0; i < bill.parcel; i++) {
        const item: IBillPayableParcel = {
          id: v4(),
          pos: i + 1,
          parcel: `${i + 1}#${bill.parcel}`,
          date_to_pay: addMonths(today, i),
          value: parcelValues[i],
        };
        items.push(item);
      }

      setBill({ ...bill, parcels: items });
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const clearAll = () => {
    setBill({ ...bill, parcel: 1, parcels: [] });
  };

  const summary = bill.parcels.reduce((a, i) => {
    a += i.value;
    return a;
  }, 0);

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      console.log('aquiv ');

      // if (bill.value !== summary) {
      //   formRef.current?.setErrors({
      //     value: 'O valor do documento é diferente do valor das parcelas',
      //   });
      //   toast(
      //     'warn',
      //     'Alerta',
      //     'Verifique o valor das parcelas com o valor final do documento',
      //   );
      //   return;
      // }

      const schema = Yup.object({
        value: Yup.number().required('Informe o valor total do documento'),
        parcel: Yup.number().required('Informe a quantidade de parcelas'),
        date_to_pay: Yup.date().required('Informe o vencimento inicial'),
        cost_center_id: Yup.string()
          .uuid()
          .required('Informe o centro de custo'),
        parcels: Yup.array().min(1, 'Informe pelo menos uma parcela'),
      });

      await schema.validate(bill, { abortEarly: false });

      await api
        .post('/bills-payable/v2/create', {
          bill: {
            provider_id: bill.provider_id,
            cost_center_id: bill.cost_center_id,
            doc_serie: bill.doc_serie,
            doc_number: bill.doc_number,
            payment_condition_id: bill.payment_condition_id,
            occorrence: bill.parcel === 1 ? 'UN' : 'PA',
            emited: today,
            date_to_pay: bill.date_to_pay,
            value: bill.value,
            obs: bill.obs,
          },
          parcels: bill.parcels,
        })
        .then(res => {
          if (res.status === 201) {
            toast('success', 'Registros criados com sucesso');
            clearAll();
            onRequestClose();
          }
        });
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const onCellEditComplete = (e: ColumnEventParams) => {
    let { newRowData, rowData } = e;

    let filteredParcels = bill.parcels.filter(p => p.id !== rowData.id);
    filteredParcels.push(newRowData);
    filteredParcels = filteredParcels.sort((a, b) => a.pos - b.pos);
    setBill({ ...bill, parcels: filteredParcels });
  };

  useEffect(() => {
    Promise.all([receiveData(data), loadCostCenter(), loadPaymentCondition()]);
  }, [isOpen]);

  return (
    <ModalPayments
      header="Gerar Contas a Pagar"
      visible={isOpen}
      onHide={() => onRequestClose()}
      style={{ width: '70vw' }}
    >
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="p-fluid grid formgrid"
      >
        <div className="field col-8">
          <label htmlFor="provider_id">Fornecedor</label>
          <InputText
            name="provider_id"
            placeholder="Ex.: Industrias ACME Ltda"
            value={bill.provider_name}
            onChange={e => null}
            disabled
            readOnly
            className="surface-200"
          />
        </div>
        <div className="field col-2">
          <label htmlFor="doc_serie">Série</label>
          <InputText
            name="doc_serie"
            placeholder="Ex.: NFE"
            value={bill.doc_serie}
            onChange={e => null}
            disabled
            readOnly
            className="surface-200"
          />
        </div>
        <div className="field col-2">
          <label htmlFor="doc_number">Número</label>
          <InputText
            name="doc_number"
            placeholder="Ex.: 000000"
            value={bill.doc_number}
            onChange={e => null}
            disabled
            readOnly
            className="surface-200"
          />
        </div>

        <div className="field col-6">
          <label htmlFor="cost_center">Centro de custo</label>
          <InputDropDown
            name="cost_center"
            placeholder="Selecione..."
            options={costCenters}
            value={bill.cost_center_id}
            onChange={e => setBill({ ...bill, cost_center_id: e.value })}
            filter
          />
        </div>

        <div className="field col-6">
          <label htmlFor="payment_condition">Condição de pagamento</label>
          <InputDropDown
            name="payment_condition"
            placeholder="Selecione..."
            options={paymentConditions}
            value={bill.payment_condition_id}
            onChange={e => setBill({ ...bill, payment_condition_id: e.value })}
          />
        </div>

        <div className="field col-3">
          <label htmlFor="value">Valor</label>
          <InputNumber
            name="value"
            value={bill.value}
            minFractionDigits={2}
            maxFractionDigits={2}
            onChange={e => setBill({ ...bill, value: Number(e.value) })}
            placeholder="R$ 0,00"
          />
        </div>
        <div className="field col-3">
          <label htmlFor="parcel">Parcelas</label>
          <InputNumber
            name="parcel"
            value={bill.parcel}
            onChange={e => setBill({ ...bill, parcel: Number(e.value) })}
            showButtons
            minFractionDigits={2}
            maxFractionDigits={2}
            buttonLayout="horizontal"
            decrementButtonClassName="p-button-danger"
            incrementButtonClassName="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            useGrouping={false}
            placeholder="0"
          />
        </div>
        <div className="field col-3">
          <label htmlFor="date_to_pay">Primeiro vencimento</label>
          <Calendar
            name="date_to_pay"
            value={bill.date_to_pay}
            onChange={e => setBill({ ...bill, date_to_pay: e.value as Date })}
            dateFormat="d/m/yy"
            minDate={subMonths(today, 2)}
            showIcon
            placeholder="00/00/00"
          />
        </div>

        <div className="field col-12 md:col-3 flex align-items-end">
          <Button
            name="btn_gerar"
            loading={isLoad}
            type="button"
            className="p-button-info"
            icon="fa-solid fa-table-list"
            label="Gerar"
            onClick={() => generateParcels()}
            disabled={bill.value === 0 || bill.parcel === 0}
          />
        </div>

        {bill.parcels.length > 0 && (
          <DataTable
            value={bill.parcels}
            responsiveLayout="scroll"
            selectionMode="single"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            emptyMessage="Nenhuma proposta encontrada!"
            className="field col-12"
            editMode="cell"
          >
            <Column field="id" header="#" body={renderColumnPosition}></Column>
            <Column field="parcel" header="Parcela" align={'center'}></Column>
            <Column
              field="date_to_pay"
              header="Vencimento"
              body={row => renderColumnDate(row.date_to_pay)}
              editor={e => dateEditor(e)}
              onCellEditComplete={e => onCellEditComplete(e)}
              align={'center'}
            ></Column>
            <Column
              field="value"
              header="Vlr. Parcela"
              body={row => renderColumnDecimal(row.value)}
              editor={e => decimalEditor(e)}
              onCellEditComplete={e => onCellEditComplete(e)}
              footer={formatDecimal(
                bill.parcels.reduce((a, i) => {
                  a += i.value;
                  return a;
                }, 0),
              )}
            ></Column>
          </DataTable>
        )}

        <Divider />

        <div className="field col-6">
          <Button
            label="Fechar"
            type="button"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              clearAll();
              onRequestClose();
            }}
            loading={isLoad}
          />
        </div>

        <div className="field col-6">
          <Button
            label="Salvar"
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={isLoad}
          />
        </div>
      </Form>
    </ModalPayments>
  );
};
