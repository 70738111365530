/* eslint-disable react/react-in-jsx-scope */
import { ReactNode, createContext, useEffect, useState } from 'react';
import { IProductRowOs } from '../pages/consigned/order/dtos/IConOrderDTO';
import IOs from '../pages/os/dtos/IOs';
import IOsObject from '../pages/os/dtos/IOsObject';
import IOsPayment from '../pages/os/dtos/IOsPayment';
import IOsService from '../pages/os/dtos/IOsService';
import { emptyOs } from '../pages/os/types/EmptyOs';
import api from '../services/api';
import { IPaymentCondition } from '../pages/paymentCondition/dtos/IPaymentCondition';

interface IContextData {
  orderService: IOs;
  setData: (os: IOs) => void;
  clearData: () => void;
  fetchData: (id: string) => void;
  initializeData: () => void;

  addObject: (item: Partial<IOsObject>) => void;
  remObject: (id: string) => void;

  addService: (item: Partial<IOsService>) => void;
  remService: (id: string) => void;

  addProduct: (item: Partial<IProductRowOs>) => void;
  remProduct: (id: string) => void;

  addPayments: (item: Partial<IOsPayment>[]) => void;
  remPayments: (groupId: string) => void;
  remPayment: (id: string) => void;
  clearPayments: () => void;

  loadContext: boolean;

  /** Parte separada para salvar as opções (comissionados, cond. de pgtos...) */
  clearOptions: () => void;
  paymentConditions?: IPaymentCondition[];
}

export const OrderServiceContext = createContext<IContextData | undefined>(
  undefined,
);

export function OrderServiceProvider({ children }: { children: ReactNode }) {
  const [orderService, setOrderService] = useState<IOs>(emptyOs);

  const [isInitializated, setIsInitializated] = useState<boolean>(false);
  const [loadContext, setLoadContext] = useState<boolean>(false);

  const [paymentConditions, setPaymentConditions] = useState<
    IPaymentCondition[]
  >([]);

  const setData = (item: IOs) => {
    setOrderService({ ...item });
  };

  const clearData = () => {
    setOrderService(emptyOs);
  };

  const clearOptions = () => {
    setPaymentConditions([]);
  };

  const loadPaymentConditions = async () => {
    setLoadContext(true);

    await api
      .get('/payments-condition')
      .then(({ data }) => {
        if (data) {
          setPaymentConditions(data);
        }
      })
      .finally(() => setLoadContext(false));
  };

  const initializeData = async () => {
    if (!isInitializated) {
      await Promise.all([loadPaymentConditions()]);

      setIsInitializated(true);
    }
  };

  const fetchData = (id: string) => {
    api.get(`/os/${id}`).then(({ data }) => {
      if (data) {
        const editOs: IOs = {
          isEdit: true,
          osHeader: {
            id: data.id,
            b_id: data.b_id,
            os_number: data.os_number,
            nat_ope_id: data.nat_ope_id,
            customer_id: data.customer_id,
            customer: {
              id: data.customer.id,
              name: data.customer.name,
            },
            description: data.description,
            kind_atendimento: data.kind_atendimento,
            date_open: new Date(data.date_open),
            date_close: new Date(data.date_close),
            date_prevision: new Date(data.date_prevision),
            contract_id: data.contract_id,
            technical_id: data.technical_id,
            commissioned_id: data.commissioned_id || '',
            priority: data.priority,
            os_status_id: data.os_status_id,
            total_objects: data.total_objects,
            total_services: data.total_products,
            total_products: data.total_products,
            total_desc: data.total_desc,
            perc_desc: data.perc_desc,
            total_adic: data.total_adic,
            perc_adic: data.perc_adic,
            juros: data.juros,
            total: data.total,
            obs_public: data.obs_public,
            obs_private: data.obs_private,
            user_id: data.user_id,
            status: data.status,
            close: data.close,
            os_registered: data.os_registered,
          },
          osStatus: data.osStatus,
          osObjects: data.osObjects.map((i: any) => {
            return { ...i, object_title: i.object.title || '' };
          }),
          osServices: data.osServices.map((i: any) => {
            return {
              ...i,
              service_title: i.service.title,
              technical_title: i.technical?.name || null,
            };
          }),
          osProducts: data.osProducts.map((i: any) => {
            return {
              ...i,
              product_description: i.product.title || '',
              table_price_description: i.tablePrice.title || '',
              vlr_liq:
                Number(i.vlr_unit) * Number(i.qnt) +
                Number(i.adic) -
                Number(i.desc),
            };
          }),
          osPayments: data.osPayments.map((i: any) => {
            return { ...i };
          }),
          osTransporter: {
            tracking_code: data.osTransporter.tracking_code,
            post_date: data.osTransporter.post_date,
            delivery_date: data.osTransporter.delivery_date,
            transporter_id: data.osTransporter.transporter_id,
            vehicle_plate: data.osTransporter.vehicle_plate,
            driver: data.osTransporter.driver,
            kind_freight: data.osTransporter.kind_freight,
            quantity: data.osTransporter.quantity,
            kind: data.osTransporter.kind,
            brand: data.osTransporter.brand,
            seal_number: data.osTransporter.seal_number,
            weight_brute: data.osTransporter.weight_brute,
            weight_liquid: data.osTransporter.weight_liquid,
            cubage: data.osTransporter.cubage,
            cost: data.osTransporter.cost,
            obs: data.osTransporter.obs,
          },
        };

        setData({ ...editOs });
      }
    });
  };

  const addObject = (item: Partial<IOsObject>) => {
    setOrderService({
      ...orderService,
      osObjects: [
        ...orderService.osObjects.filter(i => i.id !== item.id),
        item,
      ],
    });
  };
  const remObject = (id: string) => {
    setOrderService({
      ...orderService,
      osObjects: [...orderService.osObjects.filter(i => i.id !== id)],
    });
  };

  const addService = (item: Partial<IOsService>) => {
    setOrderService({
      ...orderService,
      osServices: [
        ...orderService.osServices.filter(i => i.id !== item.id),
        item,
      ],
    });
  };
  const remService = (id: string) => {
    setOrderService({
      ...orderService,
      osServices: [...orderService.osServices.filter(i => i.id !== id)],
    });
  };

  const addProduct = (item: Partial<IProductRowOs>) => {
    setOrderService({
      ...orderService,
      osProducts: [
        ...orderService.osProducts.filter(i => i.id !== item.id),
        item,
      ],
    });
  };

  const remProduct = (id: string) => {
    setOrderService({
      ...orderService,
      osProducts: [...orderService.osProducts.filter(i => i.id !== id)],
    });
  };

  const addPayments = async (item: Partial<IOsPayment>[]) => {
    setOrderService({
      ...orderService,
      osPayments: [...orderService.osPayments, ...item],
    });
  };

  const remPayments = (groupId: string) => {
    setOrderService({
      ...orderService,
      osPayments: [
        ...orderService.osPayments.filter(i => i.group_id !== groupId),
      ],
    });
  };

  const remPayment = (id: string) => {
    setOrderService({
      ...orderService,
      osPayments: [...orderService.osPayments.filter(i => i.id !== id)],
    });
  };

  const clearPayments = () => {
    setOrderService({
      ...orderService,
      osPayments: [],
    });

    console.log('limpou pagamentos');
  };

  useEffect(() => {
    let add: number = 0;
    add += orderService.osServices.reduce((a, i) => {
      a += Number(i.adic) * Number(i.qnt);
      return a;
    }, 0);
    add += orderService.osProducts.reduce((a, i) => {
      a += Number(i.adic) * Number(i.qnt);
      return a;
    }, 0);

    let desc: number = 0;
    desc += orderService.osServices.reduce((a, i) => {
      a += Number(i.desc) * Number(i.qnt);
      return a;
    }, 0);
    desc += orderService.osProducts.reduce((a, i) => {
      a += Number(i.desc) * Number(i.qnt);
      return a;
    }, 0);

    let total: number = 0;
    total += orderService.osServices.reduce((a, i) => {
      a += Number(i.total);
      return a;
    }, 0);
    total += orderService.osProducts.reduce((a, i) => {
      a += Number(i.total);
      return a;
    }, 0);

    setData({
      ...orderService,
      osHeader: {
        ...orderService.osHeader,
        total_objects: orderService.osObjects.length,
        total_services: orderService.osServices.reduce((a, i) => {
          a += Number(i.total);
          return a;
        }, 0),
        total_products: orderService.osProducts.reduce((a, i) => {
          a += Number(i.total);
          return a;
        }, 0),
        total_adic: Number(add),
        total_desc: Number(desc),
        total: Number(total),
      },
    });
  }, [
    orderService.osObjects,
    orderService.osServices,
    orderService.osProducts,
  ]);

  return (
    <OrderServiceContext.Provider
      value={{
        orderService,
        paymentConditions,
        loadContext,
        initializeData,
        setData,
        clearData,
        clearOptions,
        fetchData,
        addObject,
        remObject,
        addService,
        remService,
        addProduct,
        remProduct,
        addPayments,
        remPayments,
        remPayment,
        clearPayments,
      }}
    >
      {children}
    </OrderServiceContext.Provider>
  );
}
