import { v4 } from 'uuid';

export type IBillPayableParcel = {
  id: string;
  pos: number;
  parcel: string;
  date_to_pay: Date;
  value: number;
};

export const emptyBillPayableParcel: IBillPayableParcel = {
  id: v4(),
  pos: 0,
  parcel: '',
  date_to_pay: new Date(),
  value: 0,
};

export type ICreateBillPayable = {
  provider_id: string;
  provider_name?: string;
  cost_center_id: string;
  doc_serie: string;
  doc_number: string;
  payment_condition_id: string;
  occorrence: string;
  emited: Date;
  date_to_pay: Date;
  parcel: number;
  value: number;
  obs: string;
  parcels: IBillPayableParcel[];
};

export const emptyBillPayable: ICreateBillPayable = {
  provider_id: '',
  cost_center_id: '',
  doc_serie: '',
  doc_number: '',
  payment_condition_id: '',
  occorrence: '',
  emited: new Date(),
  date_to_pay: new Date(),
  parcel: 1,
  value: 0,
  obs: '',
  parcels: [],
};
