import { ColumnEditorOptions } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';

export const decimalEditor = (options: ColumnEditorOptions) => {
  return (
    <InputNumber
      value={options.value}
      onValueChange={(e: any) => options.editorCallback(e.value)}
      mode="currency"
      currency="BRL"
      locale="pt-BR"
      onKeyDown={e => e.stopPropagation()}
      name={''}
    />
  );
};
