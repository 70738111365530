import { addLocale, locale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { ColumnEditorOptions } from 'primereact/column';
import languageCalendar from '../../../config/calendar';
import { addYears } from 'date-fns';

export const dateEditor = (options: ColumnEditorOptions) => {
  const today = new Date();
  addLocale('pt', languageCalendar);
  locale('pt');
  return (
    <Calendar
      value={options.value}
      onChange={(e: any) => options.editorCallback(e.value)}
      dateFormat="dd/mm/yy"
      placeholder="00/00/00"
      inputClassName="text-center"
      monthNavigator
      yearNavigator
      yearRange={`1970:${addYears(today, 20).getFullYear()}`}
    />
  );
};
