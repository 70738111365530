import { FormHandles } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import useToastContext from '../../../hooks/toast';
import IOsPayment from '../../../pages/os/dtos/IOsPayment';
import { emptyOsPayment } from '../../../pages/os/types/EmptyOsPayment';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import formatCurrency from '../../../utils/FormatCurrency';
import { useOrderService } from '../../../hooks/useOrderService';
import { InputDropDown } from '../../Inputs/InputDropDown';
import Calendar from '../../Inputs/InputCalendar';
import { InputNumber } from '../../Inputs/InputNumber';
import { addDays } from 'date-fns';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getErrorsValidation';
import PerfectDivision from '../../../utils/perfectDivision';
import { v4 } from 'uuid';
import messageRequestError from '../../../utils/messageRequestError';
import renderColumnDate from '../../DataTableColumns/RenderColumnDate';
import { Column, ColumnEventParams } from 'primereact/column';
import renderColumnDecimal from '../../DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../DataTableColumns/RenderColumnPosition';
import { DataTable } from '../../DataTable';
import { EnumPayments } from '../../../enum/EnumPayments';
import { decimalEditor } from '../../DataTable/decimal-editor';
import formatDecimal from '../../../utils/numbers/FormatDecimal';
import { dateEditor } from '../../DataTable/date-editor';

interface IProps {
  show: {
    obs: boolean;
  };
  className?: string;
  isOpen?: boolean;
}

const InputRowPaymentsOS: React.FC<IProps> = ({
  show,
  className,
  isOpen,
}: IProps) => {
  /** refs & focus */
  const formRef = useRef<FormHandles>(null);
  const paymentConditionRef = useRef<HTMLSelectElement>(null);
  const [paymentConditionFocus, setPaymentConditionFocus] =
    useState<boolean>(false);

  const valueRef = useRef<HTMLInputElement>(null);
  const [valueFocus, setValueFocus] = useState<boolean>(false);

  const toast = useToastContext();

  /** states */
  const [load, setLoad] = useState<boolean>(false);
  const today = new Date();

  const { setData, orderService, paymentConditions, addPayments, remPayments } =
    useOrderService();

  const [payment, setPayment] = useState<IOsPayment>(emptyOsPayment);
  const [rest, setRest] = useState<number>(0);
  const [paied, setPaied] = useState<number>(0);

  const [showFirstParcelField, setShowFirstParcelField] =
    useState<boolean>(false);

  /** effects */

  useEffect(() => {
    let showFirstParcel = false;

    if (payment.payment) {
      if (
        payment.payment.sigla === EnumPayments.BOLETO ||
        payment.payment.sigla === EnumPayments.DUPLICATA
      ) {
        showFirstParcel = true;
        setPayment({
          ...payment,
          first_parcel: addDays(today, payment.payment.variacao_dias || 0),
        });
      }
    }

    setShowFirstParcelField(showFirstParcel);
  }, [payment.payment]);

  useEffect(() => {
    const totalPayments = orderService.osPayments.reduce(
      (a, i) => (a += Number(i.value_parcel) ? Number(i.value_parcel) : 0),
      0,
    );

    let _rest = Number(orderService.osHeader.total);

    if (totalPayments) {
      _rest =
        Number(orderService.osHeader.total) - totalPayments < 0
          ? 0
          : Number(orderService.osHeader.total) - totalPayments;
    }

    setRest(_rest);
    setPaied(totalPayments);
    setPayment({ ...emptyOsPayment, value_parcel: _rest });

    if (_rest > 0 && _rest !== Number(orderService.osHeader.total)) {
      setPaymentConditionFocus(true);
      paymentConditionRef.current?.focus();
      paymentConditionRef.current?.click();
    }
  }, [orderService.osPayments]);

  /** functions */
  const handleAddPayment = async () => {
    setLoad(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object({
        payment_id: Yup.string()
          .uuid('Id da condição de pagamento inválido!')
          .required('Informe a condição de pagamento!'),
        value_parcel: Yup.number()
          .positive('Informe um valor positivo para o pagamento!')
          .required('Informe o valor do pagamento!'),
      });

      await schema.validate(payment, { abortEarly: false });

      if (paymentConditions && paymentConditions.length > 0) {
        const paymentCondition = paymentConditions.find(
          p => p.id === payment.payment_id,
        );
        if (paymentCondition) {
          const valueParcels = PerfectDivision(
            payment.value_parcel,
            paymentCondition.qnt_parcelas,
          );

          const payments: Partial<IOsPayment>[] = [];

          let pos: number = 0;

          const groupId = v4();

          for (const value of valueParcels) {
            payments.push({
              id: v4(),
              description: `${paymentCondition.title} - ${pos + 1}/${
                paymentCondition.qnt_parcelas
              }`,
              parcel: pos + 1,
              value_parcel: value,
              value_doc: payment.value_parcel,
              payment: paymentCondition,
              payment_id: payment.payment_id,
              group_id: groupId,
              due_date: payment.first_parcel
                ? addDays(
                    payment.first_parcel,
                    Number(paymentCondition.variacao_dias) * pos,
                  )
                : paymentCondition.variacao_dias === 1 ||
                  paymentCondition.variacao_dias === 0
                ? today
                : addDays(
                    today,
                    Number(paymentCondition.variacao_dias) * (pos + 1),
                  ),
            });

            pos++;
          }
          addPayments(payments);
        }
      }
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Erro', err.errors[0]);
      } else {
        toast('error', 'Erro', messageRequestError(err));
      }
    } finally {
      setLoad(false);
    }
  };

  const handleRemovePayment = (groupId: string) => {
    remPayments(groupId);
  };

  const renderColumnRemPay = (groupId: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          type="button"
          onClick={() => handleRemovePayment(groupId)}
        />
      </div>
    );
  };

  console.log(orderService.osPayments);

  const onCellEditComplete = (e: ColumnEventParams) => {
    let { newRowData, rowData } = e;

    let filteredPayments = orderService.osPayments.filter(
      p => p.id !== rowData.id,
    );

    filteredPayments.push(newRowData);
    filteredPayments = filteredPayments.sort(
      (a, b) => a.due_date?.getTime() - b.due_date?.getTime(),
    );

    const summary = filteredPayments.reduce((a, i) => {
      a += Number(i.value_parcel);
      return a;
    }, 0);

    if (summary !== orderService.osHeader.total) {
      toast(
        'warn',
        'Alerta',
        `O valor editado das parcelas [${formatDecimal(
          summary,
        )}] é diferente do valor total do pedido [${formatDecimal(
          orderService.osHeader.total || 0,
        )}].`,
      );
      return;
    }
    setData({ ...orderService, osPayments: [] });
    setData({ ...orderService, osPayments: [...filteredPayments] });
  };

  /** templates */

  return (
    <>
      <Form
        ref={formRef}
        className="p-fluid grid formgrid"
        onSubmit={handleAddPayment}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div
          className={`field ${showFirstParcelField ? 'col-4' : 'col-7'}`}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();

              setPaymentConditionFocus(false);
              paymentConditionRef.current?.blur();

              setValueFocus(true);
              valueRef.current?.focus();
            }
          }}
        >
          <label htmlFor="payment_id">Condição de pagamento</label>
          <InputDropDown
            name="payment_id"
            inputRef={paymentConditionRef}
            value={payment.payment_id}
            options={
              paymentConditions
                ? paymentConditions.map(p => {
                    return {
                      value: p.id,
                      label: p.title,
                    };
                  })
                : []
            }
            onChange={e =>
              setPayment({
                ...payment,
                payment_id: e.value,
                payment: paymentConditions?.find(p => p.id === e.value),
              })
            }
            emptyMessage={'Nenhuma condição encontrada'}
            placeholder="Selecionar..."
            disabled={orderService.osHeader.total === 0}
            className={orderService.osHeader.total === 0 ? `surface - 200` : ``}
            autoFocus={paymentConditionFocus}
            filter
          />
        </div>

        {showFirstParcelField && (
          <div className="field col-3">
            <label htmlFor="first_parcel">Data 1ª parcela</label>
            <Calendar
              name="first_parcel"
              value={payment.first_parcel}
              onChange={e =>
                setPayment({ ...payment, first_parcel: e.value as Date })
              }
              dateFormat="dd/mm/yy"
              showIcon
              disabled={load}
              onSelect={() => {
                valueRef.current?.focus();
                setValueFocus(true);
              }}
            />
          </div>
        )}

        <div className="field col-3">
          <label htmlFor="value_parcel">Valor</label>

          <InputNumber
            name="value_parcel"
            inputRef={valueRef}
            autoFocus={valueFocus}
            value={payment.value_parcel}
            onChange={e => {
              if (e.value !== null)
                setPayment({ ...payment, value_parcel: e.value as number });
            }}
            placeholder="0,00"
            minFractionDigits={2}
            maxFractionDigits={2}
            min={0}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleAddPayment();

                e.preventDefault();

                setValueFocus(false);
                valueRef.current?.blur();
              }
            }}
          />
          <Tag
            severity={
              paied >= Number(orderService.osHeader.total) ? 'success' : 'info'
            }
            value={`Falta ${formatCurrency(rest)}`}
            className="ml-1"
          />
        </div>

        <div className="field col-2 flex align-items-center">
          <Button
            name="add"
            icon="fa-solid fa-plus"
            type="button"
            onClick={handleAddPayment}
            className="p-button-success w-full mt-1"
            disabled={
              payment.payment_id === '' ||
              payment.payment_id === null ||
              payment.value_parcel === 0
            }
          />
        </div>
      </Form>

      <div className="flex justify-content-between">
        <Tag
          severity={
            paied >= Number(orderService.osHeader.total) ? 'success' : 'info'
          }
          value={`Pago: ${formatCurrency(paied)}`}
        ></Tag>
        {paied > Number(orderService.osHeader.total) && (
          <Tag
            severity="warning"
            value={`Troco: ${formatCurrency(
              paied - Number(orderService.osHeader.total),
            )}`}
          ></Tag>
        )}
      </div>

      <DataTable
        value={orderService.osPayments}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        emptyMessage="Nenhum pagamento adicionado..."
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} pagamentos."
        className="w-full mb-2"
      >
        <Column header="#" body={renderColumnPosition}></Column>
        <Column header="Descrição" field="description"></Column>
        <Column
          header="Valor"
          field="value_parcel"
          body={r => renderColumnDecimal(r.value_parcel)}
          editor={e => decimalEditor(e)}
          onCellEditComplete={e => onCellEditComplete(e)}
          footer={formatDecimal(
            orderService.osPayments.reduce((a, i) => {
              a += i.value_parcel || 0;
              return a;
            }, 0),
          )}
        ></Column>
        <Column
          header="Vencimento"
          field="due_date"
          body={r => renderColumnDate(r.due_date)}
          editor={e => dateEditor(e)}
          onCellEditComplete={e => onCellEditComplete(e)}
        ></Column>
        <Column header="" body={r => renderColumnRemPay(r.group_id)}></Column>
      </DataTable>
    </>
  );
};

export default InputRowPaymentsOS;
